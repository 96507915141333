import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chatbot from "./Chatbot";
import ResultScreen from "./ResultScreen";

const ChatbotPage = () => {
  const [step, setStep] = useState(1);
  const [finalMessg, setFinalMessg] = useState("");

  useEffect(() => {
    const savedStep = localStorage.getItem("meloChatStep");
    if (savedStep) {
      setStep(parseInt(savedStep, 10));
    }
    const savedFinalMessg = localStorage.getItem("meloChatFinalMessg");
    if (savedFinalMessg) {
      setFinalMessg(savedFinalMessg);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("meloChatStep", step);
  }, [step]);

  useEffect(() => {
    localStorage.setItem("meloChatFinalMessg", finalMessg);
  }, [finalMessg]);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col items-center">
        <div className="flex-1 flex items-center justify-center w-full">
          {step === 1 ? (
            <Chatbot setStep={setStep} setFinalMessg={setFinalMessg} />
          ) : step === 2 ? (
            <ResultScreen finalMessg={finalMessg} setStep={setStep} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChatbotPage;
