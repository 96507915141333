import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import CoverImage from "./CoverImage";
import CaptionCard from "./CaptionCard";

const SingleStory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [story, setStory] = useState(null);
  const [storyPart1, setStoryPart1] = useState(null);
  const [storyPart2, setStoryPart2] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [captions, 
    setCaptions,] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/story/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setStory(response.data.data);
        setCaptions(response.data.data.captions)
        setPageLoading(false);
        if(response?.data?.data?.description?.split("<h4>Your values</h4>")[1]){
          setStoryPart1(response?.data?.data?.description?.split("<h4>Your values</h4>")[0]);
          setStoryPart2(response?.data?.data?.description?.split("<h4>Your values</h4>")[1]);
        }else{
          setStoryPart1(response?.data?.data?.description?.split("<h4>Your Values</h4>")[0]);
          setStoryPart2(response?.data?.data?.description?.split("<h4>Your Values</h4>")[1]);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        console.error(errorMessage);
        setPageLoading(false);
      });
  }, [id]);

  if (pageLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!story) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No story found</p>
      </div>
    );
  }

  const turnStory = (id) => {
    navigate(`/turn-your-story-into-a-film/${id}`);
  };

  const copyToClipboard = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    const textToCopy = tempDiv.textContent || tempDiv.innerText || "";

    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    });
  };

  const generateAndDownloadPDF = async () => {
    try {
      setLoading(true);
      // const htmlContent =
      //   `<h4 style="text-align:center">${story.name}</h4>` + story.description;
let htmlContent=""
const data={}
      if(storyPart1 && storyPart2){
        htmlContent =
        `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>`;
        data['storyPart1']=storyPart1
        data['storyPart2']='<h4>Your Values</h4>' + storyPart2
      
      }else{
        htmlContent =
        `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>` +
        story.description;
      }
      data['htmlContent']=htmlContent
      data['storyId']=id
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/generate-pdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Error saving pdf file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "story.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      toast.error("Error: " + e.message);
      setLoading(false);
    }
  };

  const htmlToPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const traverse = (node) => {
      let text = "";
      const children = Array.from(node.childNodes);

      children.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          text += child.nodeValue;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          switch (child.tagName) {
            case "H1":
            case "H2":
            case "H3":
            case "H4":
            case "H5":
            case "H6":
              text += `${traverse(child).trim()}\n`;
              break;
            case "P":
              text += `${traverse(child).trim()}`;
              break;
            case "UL":
              text += traverseList(child, "ul");
              break;
            case "OL":
              text += traverseList(child, "ol");
              break;
            case "LI":
              text += `${traverse(child).trim()}\n`;
              break;
            case "BR":
              text += "\n";
              break;
            default:
              text += traverse(child);
          }
        }
      });

      return text;
    };

    const traverseList = (node, listType) => {
      let text = "";
      const children = Array.from(node.children);

      children.forEach((child, index) => {
        if (listType === "ul") {
          text += `• ${traverse(child).trim()}\n`;
        } else if (listType === "ol") {
          text += `${index + 1}. ${traverse(child).trim()}\n`;
        }
      });

      return text;
    };

    return traverse(tempDiv).trim();
  };

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div>
        <h3 className="text-center font-semibold leading-6 text-gray-900">
          {story.name}
        </h3>
        {
          storyPart1 && (
            <div
          dangerouslySetInnerHTML={{ __html: storyPart1 }}
          className="text-content pb-4"
        />
          )
        }
        {
          story.image && (<CoverImage
          imageFile={story.image}
          storyId={story._id}
        />)
        }
        
        {captions?.length > 0 && (<><h4>Your Content</h4>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {captions.map((caption) => (
          <div
            key={caption._id}
            className="relative flex flex-col items-center"
          >
            <CaptionCard caption={caption}
            
            />
          </div>
        ))}
      </div></>)}
      {
        storyPart2 && (<div
          dangerouslySetInnerHTML={{ __html: '<h4>Your Values</h4>' + storyPart2 }}
          className="text-content pb-16"
        />)
      }
      </div>

      <div className="max-w-4xl mx-auto fixed pt-4 pb-8 bottom-0 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <i
            className="relative cursor-pointer rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 fas fa-copy"
            onClick={() => copyToClipboard(story.description)}
            title="Copy to Clipboard!"
          >
            {showTooltip && (
              <div className="absolute -top-8 right-0 text-xs rounded bg-black text-white py-1 px-2">
                Copied!
              </div>
            )}
          </i>
          <button
            onClick={() => generateAndDownloadPDF()}
            className="rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            title="Download PDF"
            disabled={loading}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              <i className="fas fa-file-pdf"></i>
            )}
          </button>
          <button
            className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            onClick={() =>
              shareOnMobile({
                text: "hello how are you",
                title: htmlToPlainText(story),
              })
            }
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleStory;
