import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Checkout = () => {
  const navigate = useNavigate();

  const makePayment = async () => {
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/checkout`,
        {
          userMessage: `Take into account previous messages and Write my story. Do not add **. Use only below HTML tags for formatting. Use <h4> for headings, <p> for paragraphs, and <ul> or <ol> along with <li> for lists and bullet points. The content should be structured and formatted clearly with these above html tags only. Ensure that these below four headings are present.
        <h4>Your story</h4> [[minimum 400 words of story]]
        <h4>Your values</h4>
        <h4>Your content strategy</h4>
        <h4>Your digital media schedule</h4>`,
          chatHistory: JSON.parse(localStorage.getItem("meloChatMessages")),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        stripe.redirectToCheckout({ sessionId: response.data.message });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };
  return (
    <div className="max-w-md mx-auto p-4">
      <ToastContainer />
      <h3 className="text-center font-semibold leading-6 text-gray-900">
        Order Summary
      </h3>

      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <div className="space-y-4 p-4">
          <p className="font-medium text-gray-700 hover:text-gray-800">
            Story Bundle
          </p>

          <p className="mt-1 text-sm">Includes</p>
          <ol className="ml-4">
            <li className="text-sm text-gray-500">Your Story</li>
            <li className="text-sm text-gray-500">Your Values</li>
            <li className="text-sm text-gray-500">Your content strategy</li>
            <li className="text-sm text-gray-500">
              Your digital media schedule
            </li>
          </ol>
        </div>

        <dl className="space-y-6 border-t border-gray-200">
          <div className="flex items-center justify-between p-4">
            <dt className="text-base font-medium">Total</dt>
            <dd className="text-base font-medium text-gray-900">$50.00</dd>
          </div>
        </dl>

        <div class="border-t border-gray-200 sm:flex sm:flex-row-reverse px-4 py-6">
          <button
            class="rounded-md transition-all w-full bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-75 sm:ml-3 sm:w-1/2"
            onClick={makePayment}
          >
            Proceed to Pay
          </button>
          <button
            class="rounded-md transition-all mt-3 w-full bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-1/2 "
            onClick={() => navigate("/start-your-story")}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
