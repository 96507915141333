import { useEffect, useState } from 'react'

export default function CoverImage({imageFile,storyId}) {

  const [image, setImage] = useState(null)

  useEffect(() => {
    if (imageFile && !image) {
     
        setImage(imageFile)
    }
  }, [imageFile])


  return (
    <div className="pb-8">
      <h4>Image Upload</h4>
      <div className="space-y-4">
          <img src={image} alt="Uploaded" className="w-full object-cover" />
          
        </div>
    </div>
  )
}