import React, { useContext, useState, useEffect } from "react";
import Sidebar, { SidebarItem } from "../Sidebar/Sidebar";
import axios from "axios";
import {
  LayoutDashboard,
  BookA,
  BookOpen,
  ClipboardList,
  LogOut,
  Terminal,
  
} from "lucide-react";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Story from "../Pages/Story";

const DashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    newUsers: 0,
    totalRevenue: 0,
    totalOrders: 0,
    totalStories: 0,
    totalChats: 0,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setDashboardData(response.data.data);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast.error(errorMessage);
      });
  }, []);

  return (
    <div className="space-y-4">
      <h2>Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-green-600">
                <i className="fa fa-wallet fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">
                Total Revenue
              </h4>
              <p className="text-xl font-bold">
                ${dashboardData.totalRevenue}{" "}
                <span className="text-green-500">
                  <i className="fas fa-caret-up"></i>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-pink-600">
                <i className="fas fa-users fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">Total Users</h4>
              <p className="font-bold text-xl">
                {dashboardData.totalUsers}{" "}
                <span className="text-pink-500">
                  <i className="fas fa-exchange-alt"></i>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-yellow-600">
                <i className="fas fa-user-plus fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">New Users</h4>
              <p className="font-bold text-xl">
                {dashboardData.newUsers}{" "}
                <span className="text-yellow-600">
                  <i className="fas fa-caret-up"></i>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 border-blue-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-blue-600">
                <i className="fas fa-server fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">
                Total Orders
              </h4>
              <p className="font-bold text-xl">{dashboardData.totalOrders}</p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-orange-200 to-orange-100 border-b-4 border-orange-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-orange-600">
                <i className="fa-solid fa-book fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">
                Total Stories
              </h4>
              <p className="font-bold text-xl">{dashboardData.totalStories}</p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-violet-200 to-violet-100 border-b-4 border-violet-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-violet-600">
                <i className="fa-solid fa-comments fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h4 className="font-bold uppercase text-gray-600">Total Chats</h4>
              <p className="font-bold text-xl">{dashboardData.totalChats}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return <DashboardComponent />;
      case "Story":
   return <Story/>
      default:
        return (
          <div className="space-y-4">
            <h2>Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
              <div
                className="bg-white p-4 border border-violet-500 rounded hover:bg-violet-500 hover:text-white cursor-pointer"
                onClick={() => setActiveComponent("Generate Learning Activity")}
              >
                <BookA size={40} className="mx-auto mb-2" />
                <h2 className="text-center text-lg font-semibold">
                  Generate Learning Activity
                </h2>
              </div>
              <div
                className="bg-white p-4 border border-violet-500 rounded hover:bg-violet-500 hover:text-white cursor-pointer"
                onClick={() => setActiveComponent("Generate Learning Plan")}
              >
                <BookOpen size={40} className="mx-auto mb-2" />
                <h2 className="text-center text-lg font-semibold">
                  Generate Learning Plan
                </h2>
              </div>
              <div
                className="bg-white p-4 border border-violet-500 rounded hover:bg-violet-500 hover:text-white cursor-pointer"
                onClick={() => setActiveComponent(" Generate Assessment Task")}
              >
                <ClipboardList size={40} className="mx-auto mb-2" />
                <h2 className="text-center text-lg font-semibold">
                  Generate Assessment Task
                </h2>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="bg-white">
      {/* <ToastContainer /> */}
      {/* <div className="dashboardContainer mx-auto flex">
        <Sidebar>
          <br />
          <SidebarItem
            icon={<LayoutDashboard size={20} />}
            text="Dashboard"
            active={activeComponent === "Dashboard"}
            onClick={() => setActiveComponent("Dashboard")}
          />
          <hr className="my-3" />
          <SidebarItem
            icon={<BookOpen size={20} />}
            text="Story"
            active={activeComponent === "Story"}
            onClick={() => setActiveComponent("Story")}
          />
<SidebarItem
            icon={<Terminal size={20} />}
            text="Prompt"
            active={activeComponent === "Prompt"}
            onClick={() => setActiveComponent("Prompt")}
          />
          <SidebarItem
            icon={<LogOut size={20} />}
            text="Logout"
            onClick={() => {
              logout();
              navigate("/login");
            }}
          />
        </Sidebar>

        <div className="contentContainer flex-grow p-4">
          {renderActiveComponent()}
        </div>
      </div> */}
    </div>
  );
};

export default DashboardComponent;
