import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Navbar from "../components/ui/NavBar";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i class="fa-solid fa-spinner animate-spin"></i>
      </div>
    ); // Show loading state while checking authentication
  }

  return isAuthenticated ? <div class="container mx-auto">
  <Navbar />
  {children}
</div> : <Navigate to="/login" />;

};

export default ProtectedRoute;
