import { BiEdit } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { LuImagePlus, LuShare2 } from "react-icons/lu";

export default function CaptionCard({
    caption,
    
}) {

  return (
    <>
        <div className="w-full aspect-square mb-2 bg-lightgray overflow-hidden">
              {caption?.url && (
                <img
                  src={caption.url}
                  alt={''}
                  
                  className="w-full h-full object-cover"
                />
              ) }
              
              
            </div>
            <p className="text-sm text-center text-darkergray">
              {caption.text}{' '}
            </p>
            
    </>
  )
}