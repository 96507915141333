import { useForm } from 'react-hook-form'
import { useState } from 'react'

export default function CouponEdit({ onSubmit, initialData }) {
  console.log('initialData:', initialData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialData,
      expiryDate: initialData?.expiryDate? new Date(initialData.expiryDate).toISOString().slice(0, 16) : '',
    } || {
      code: '',
      discountPercentage: '',
      expiryDate: '',
      isActive: true,
      maxRedemptions: '',
    },
  })

  const submitForm = (data) => {
    let expiryDate = new Date(data.expiryDate)
    expiryDate?.setHours(expiryDate.getHours() + 5)
    onSubmit({
      ...data,
      expiryDate: expiryDate?.toISOString(),
    });
  };

  return (

    <form onSubmit={handleSubmit(submitForm)} className="space-y-6 p-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                CODE
              </label>
              <input
                {...register('code', { required: 'Code is required' })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter coupon code"
              />
              {errors.code && (
                <p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                DISCOUNT PERCENTAGE
              </label>
              <div className="relative">
                <input
                  {...register('discountPercentage', {
                    required: 'Discount percentage is required',
                    min: { value: 0, message: 'Must be at least 0' },
                    max: { value: 100, message: 'Must not exceed 100' },
                  })}
                  type="number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-8"
                  placeholder="Enter discount percentage"
                />
                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">
                  %
                </span>
              </div>
              {errors.discountPercentage && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.discountPercentage.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                EXPIRY DATE
              </label>
              <input
                {...register('expiryDate', { required: 'Expiry date is required' })}
                type="datetime-local"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.expiryDate && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.expiryDate.message}
                </p>
              )}
            </div>

            <div className="flex items-center space-x-2">
              <input
                {...register('isActive')}
                type="checkbox"
                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label className="text-sm font-medium text-gray-700">
                Is Active
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                MAX REDEMPTIONS
              </label>
              <input
                {...register('maxRedemptions', {
                  required: 'Max redemptions is required',
                  min: { value: 1, message: 'Must be at least 1' },
                })}
                type="number"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter max redemptions"
              />
              {errors.maxRedemptions && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.maxRedemptions.message}
                </p>
              )}
            </div>


            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </form>
  );
}