import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ResultScreen.css";

const ResultScreen = ({ finalMessg, setStep }) => {
  const [loading, setLoading] = useState(false);

  const generateAndDownloadPDF = async () => {
    try {
      setLoading(true);
      const htmlContent = finalMessg;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/generate-pdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent }),
        }
      );
      if (!response.ok) {
        throw new Error("Error saving pdf file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "story.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      toast.error("Error: " + e.message);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div
        dangerouslySetInnerHTML={{ __html: finalMessg }}
        className="text-content pb-16 blurred-content"
      />

      <div className="max-w-4xl mx-auto fixed pt-4 pb-8 bottom-0 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <div
            onClick={() => generateAndDownloadPDF()}
            className="text-black px-4 py-2 rounded-md border border-gray-400"
            title="Download PDF"
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              <i className="fas fa-file-pdf cursor-pointer"></i>
            )}
          </div>
          <Link
            to="/checkout"
            className="text-white px-4 py-2 rounded-md bg-black"
          >
            Purchase for $50
          </Link>
          <button
            onClick={() => {
              setStep(1);
              localStorage.setItem("meloChatStep", 1);
              localStorage.removeItem("meloChatMessages");
              localStorage.removeItem("meloChatFinalMessg");
            }}
            className="text-black px-4 py-2 rounded-md border border-gray-400"
          >
            Start again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultScreen;
