import React from "react";
import { useNavigate, useParams } from "react-router-dom";


const OrderSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div class="relative z-10" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div class="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="flex flex-col items-center justify-center">
                  <div class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0">
                    <img src="https://melo-story.s3.eu-north-1.amazonaws.com/dollar-coin.png"></img>
                  </div>
                  <div class="mt-6 sm:text-left">
                    <h3 class="text-center font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </h3>
                    <div class="mt-3 text-center text-sm text-gray-500">
                      <p>Thank you for your payment</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 px-4 py-3 sm:px-6">
                <button
                  class="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75"
                  onClick={() => navigate(`/story`)}
                >
                  See your story
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
